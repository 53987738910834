import _url from "url";
import _prependHttp from "prepend-http";
var exports = {};
const url = _url;
const prependHttp = _prependHttp;

exports = (input, options) => {
  if (typeof input !== "string") {
    throw new TypeError(`Expected \`url\` to be of type \`string\`, got \`${typeof input}\` instead.`);
  }

  const finalUrl = prependHttp(input, Object.assign({
    https: true
  }, options));
  return url.parse(finalUrl);
};

export default exports;